// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-changelog-js": () => import("./../../../src/pages/changelog.js" /* webpackChunkName: "component---src-pages-changelog-js" */),
  "component---src-pages-client-js": () => import("./../../../src/pages/client.js" /* webpackChunkName: "component---src-pages-client-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-intro-bls-tool-features-js": () => import("./../../../src/pages/intro/bls-tool-features.js" /* webpackChunkName: "component---src-pages-intro-bls-tool-features-js" */),
  "component---src-pages-intro-built-in-conferencing-js": () => import("./../../../src/pages/intro/built-in-conferencing.js" /* webpackChunkName: "component---src-pages-intro-built-in-conferencing-js" */),
  "component---src-pages-intro-past-present-future-js": () => import("./../../../src/pages/intro/past-present-future.js" /* webpackChunkName: "component---src-pages-intro-past-present-future-js" */),
  "component---src-pages-intro-steps-to-getting-started-js": () => import("./../../../src/pages/intro/steps-to-getting-started.js" /* webpackChunkName: "component---src-pages-intro-steps-to-getting-started-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-session-js": () => import("./../../../src/pages/session.js" /* webpackChunkName: "component---src-pages-session-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-therapist-jsx": () => import("./../../../src/pages/therapist.jsx" /* webpackChunkName: "component---src-pages-therapist-jsx" */)
}

